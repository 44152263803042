module.exports = {
  siteTitle: 'Bastian Situmorang', // <title>
  manifestName: 'Bastian Situmorang',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  pathPrefix: `/bastiansitumorang.id/`,
  // social
  heading: 'Bastian Situmorang',
  subHeading:
    "Software Engineer",
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/situmorangbastian',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/bastianpaskal',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:bastianpaskal@gmail.com',
    },
  ],
};
